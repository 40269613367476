import React, { SyntheticEvent, useState } from "react";
import "./AddForm.css";
import { Btn } from "../common/Btn";
import { geocode } from "../../utils/geocoding";
import { apiUrl } from "../../config/api";

export const AddForm = () => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");

  const [form, setForm] = useState({
    name: "",
    description: "",
    price: 0,
    url: "",
    address: "",
  });

  const saveAdv = async (e: SyntheticEvent) => {
    e.preventDefault();

    setLoading(true);

    try {
      const { lat, lon } = await geocode(form.address);

      const res = await fetch(`${apiUrl}/adv`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...form,
          lat,
          lon,
        }),
      });
      const data = await res.json();

      setId(data.id);
    } finally {
      setLoading(false);
    }
  };

  const updateForm = (key: string, value: any) => {
    setForm((form) => ({
      ...form,
      [key]: value,
    }));
  };

  if (loading) {
    return <h2>Advertise is now in progress to be added...</h2>;
  }

  if (id) {
    return (
      <h2>
        Your Advertise {form.name} has been added to the map with id:{id}
      </h2>
    );
  }

  return (
    <form action="" className="add-form" onSubmit={saveAdv}>
      <h1>Add Form</h1>
      <p>
        <label>
          Name
          <br />
          <input
            type="text"
            name="name"
            required
            maxLength={99}
            value={form.name}
            onChange={({ target }) => updateForm("name", target.value)}
          />
        </label>
      </p>

      <p>
        <label>
          Description
          <br />
          <textarea
            name="description"
            maxLength={999}
            value={form.description}
            onChange={({ target }) => updateForm("description", target.value)}
          />
        </label>
      </p>

      <p>
        <label>
          Price
          <br />
        </label>
        <input
          type="number"
          name="price"
          maxLength={99}
          value={form.price}
          onChange={({ target }) => updateForm("price", Number(target.value))}
        />
        <small>Remain here zero to not display the price</small>
      </p>

      <p>
        <label>
          Address URL
          <br />
        </label>
        <input
          type="url"
          name="url"
          maxLength={99}
          value={form.url}
          onChange={({ target }) => updateForm("url", target.value)}
        />
      </p>

      <p>
        <label>
          Physical address on the map
          <br />
        </label>
        <input
          type="text"
          name="address"
          required
          maxLength={99}
          value={form.address}
          onChange={({ target }) => updateForm("address", target.value)}
        />
      </p>

      <Btn text="Save" />
    </form>
  );
};
