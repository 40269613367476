import React, { useContext, useEffect, useState } from "react";
import { AdvEntity } from "types";
import {apiUrl} from "../../config/api";

interface Props {
  id: string | undefined;
}

export const SingleAdv = (props: Props) => {
  const [adv, setAdv] = useState<AdvEntity | null>(null);

  useEffect(() => {
    (async () => {
      const res = await fetch(`${apiUrl}/adv/${props.id}`);
      const data = await res.json();

      setAdv(data);
    })();
  }, []);

  if (adv === null) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <h2>{adv.name}</h2>
      <p>{adv.description}</p>

      {adv.price && (
        <p>
          <strong>{adv.price}$</strong>
        </p>
      )}
      <hr />

      <a href={adv.url} target="_blank" rel="noreferrer">
        Open advertisement
      </a>
    </>
  );
};
