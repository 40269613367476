import React, { SyntheticEvent, useContext, useState } from "react";
import "./Header.css";
import { Btn } from "../common/Btn";
import { SearchContext } from "../../context/search.context";
import { Link } from "react-router-dom";

export const Header = () => {
  const { search, setSearch } = useContext(SearchContext);
  const [inputValue, setInputValue] = useState(search);

  const setSearchFromLocalState = (e: SyntheticEvent) => {
    e.preventDefault();
    setSearch(inputValue);
  };

  return (
    <header>
      <h1>
        <Link to="/" style={{ textDecoration: "none", color: "white" }}>
          <strong>Mega</strong>Advertisement
        </Link>
      </h1>
      <Btn to="/add" text="Add Advertisement" />
      <form className="search" onSubmit={setSearchFromLocalState}>
        <input
          type="text"
          value={inputValue}
          onChange={({ target }) => {
            setInputValue(target.value);
          }}
        />
        <Btn text="search" />
      </form>
    </header>
  );
};
