import React, { useContext, useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "../../utils/fix-map-icon";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import { SearchContext } from "../../context/search.context";
import { SimpleAdvEntity } from "types";
import { SingleAdv } from "./SingleAdv";
import {apiUrl} from "../../config/api";

export const Map = () => {
  const { search } = useContext(SearchContext);
  const [ads, setAds] = useState<SimpleAdvEntity[]>([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(`${apiUrl}/adv/search/${search}`);
      const ads = await res.json();

      setAds(ads);
    })();
  }, [search]);

  return (
    <div className="map">
      {/*<h1>Search for: {search}</h1>*/}
      <MapContainer center={[52.2106056, 20.9665081]} zoom={8}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> & contributors"
        />
        {ads.map((ad) => (
          <Marker key={ad.id} position={[ad.lat, ad.lon]}>
            <Popup>
              <SingleAdv id={ad.id} />
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};
